import { DataService } from './data.service'
import { ReactiveFormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UpdateProfileComponent } from './update-profile.component'
import { RouterModule } from '@angular/router'


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            { path: '', component: UpdateProfileComponent }
        ])
    ],
    providers: [DataService],
    declarations: [UpdateProfileComponent]
})
export class UpdateProfileModule { }
