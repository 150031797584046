<section class="change-pass-section">
    <div class="change-pass-container">
        <h1 class="mb-2 text-center">Update Profile</h1>
        <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile(updateProfileForm)" #f="ngForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>First Name <span class="mendatory">*</span></label>
                        <input formControlName="firstName" class="cg mt-0" placeholder="Enter First Name"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.firstName, f)" class="errors">
                        <p *ngIf="g.firstName.errors?.required">First Name is required</p>
                    </div>
                </div>
      
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Last Name <span class="mendatory"></span></label>
                        <input formControlName="lastName" class="cg mt-0" placeholder="Enter Last Name"
                            [ngClass]="{'form-submitted': f.submitted}">
                    </div>
                    <div *ngIf="ui.checkErrors(g.lastName, f)" class="errors">
                        <p *ngIf="g.lastName.errors?.required">Last Name is required</p>
                    </div>
                </div>
                <div class="col-md-12 cg-input-group pb-1">
                    <input class="cg fadeIn" mask="(000) 000-0000" formControlName="contact" placeholder="Enter Phone Number" >
                    <div *ngIf="ui.checkErrors(g.contact, f)" class="errors">
                        <p *ngIf="g.contact.errors?.required">Phone Number is required</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address<span class="mendatory"></span></label>
                        <input formControlName="address" class="cg mt-0" placeholder="Enter Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.address, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address is required</p>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Address Two<span class="mendatory"></span></label>
                        <input formControlName="addressTwo" class="cg mt-0" placeholder="Enter Two Address" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.addressTwo, f)" class="errors">
                        <p *ngIf="g.address.errors?.required">Address  is required</p>
                    </div> -->
                </div>
              
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>House#<span class="mendatory"></span></label>
                        <input formControlName="house" class="cg mt-0" placeholder="Enter Hose" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is required</p>
                    </div>
                </div> -->
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>Street#<span class="mendatory"></span></label>
                        <input formControlName="street" class="cg mt-0" placeholder="Enter Street" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.street, f)" class="errors">
                        <p *ngIf="g.street.errors?.required">Street is required</p>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>City<span class="mendatory"></span></label>
                        <input formControlName="city" class="cg mt-0" placeholder="Enter City" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">City is required is required</p>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="cg-input-group">
                        <label>State<span class="mendatory"></span></label>
                        <input formControlName="state" class="cg mt-0" placeholder="Enter State" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <div *ngIf="ui.checkErrors(g.state, f)" class="errors">
                        <p *ngIf="g.state.errors?.required">State is required</p>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div class="cg-input-group">
                        <label>Zip Code<span class="mendatory"></span></label>
                        <input formControlName="zipCode" class="cg mt-0" placeholder="Enter Zip Code" [ngClass]="{ 'form-submitted': f.submitted }" />
                    </div>
                    <!-- <div *ngIf="ui.checkErrors(g.zipCode, f)" class="errors">
                        <p *ngIf="g.zipCode.errors?.required">Zip Code is required</p>
                    </div> -->
                </div>
                <div class="col-md-12 text-center">
                    <button type="submit" class="cg primary" [class.in-progress]="inprogress">
                        Update Profile
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</section>