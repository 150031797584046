import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { Component, OnInit } from '@angular/core'
import { DataService } from './data.service'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { Router } from '@angular/router'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'

@Component({
    selector: 'app-update-profile',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit{
    registrationError: string
    updateProfileForm: FormGroup
    name: any
    user :any
    oldDetail :any
    mentorDetail : any
    contact: any
    address: any
    form : any
    selectedId :number
    inprogress: boolean = false
    breadCrum: any = [
        {
            link: '/mentor/update-profile',
            value: 'Change Password'
        }
    ]
    constructor(
        private fb: FormBuilder,
        private dataService: DataService,
        public ui: UIHelpers,
        private router: Router,
        private alert: IAlertService,
        public api: ApiService
    ) {
        this.updateProfileForm = this.fb.group({
            firstName: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
            lastName: new FormControl(null, [Validators.maxLength(200)]),
            //house: new FormControl(null, [Validators.maxLength(100)]),
            //street: new FormControl(null, [Validators.maxLength(100)]),
            city: new FormControl(null, [Validators.maxLength(100)]),
            //state: new FormControl(null, [Validators.maxLength(100)]),
            zipCode: new FormControl(null, [Validators.maxLength(100)]),
            contact: new FormControl(null, [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            addressTwo: new FormControl(null),
            
        })
        this.mentorDetail = this.api.user.mentor
        this.selectedId = this.mentorDetail.id
        console.log(this.selectedId)
        this.oldDetail = this.mentorDetail
        this.oldDetail.contact = this.mentorDetail.contactOne
        
        // {
        //     name : this.mentorDetail.name,
        //     contact : this.mentorDetail.contactOne,
        //     address : this.mentorDetail.address
        // }
    }
    ngOnInit(): void {
        this.updateProfileForm.patchValue(this.oldDetail)
    }

    get g() {
        return this.updateProfileForm.controls
    }

    updateProfile(data: any) {
        this.inprogress = true
        // this.name = this.updateProfileForm.controls.name.value
        // this.contact = this.updateProfileForm.controls.contact.value
        // this.address = this.updateProfileForm.controls.address.value

        if (data.status === 'INVALID') {
            this.alert.error('Please fill-in valid data in all fields & try again.')
            this.inprogress = false

            return
        }
        const params = {
            id : this.selectedId,
            email:this.api.user.email,
            ...this.updateProfileForm.value

        }
        this.dataService.updateProfile(params).subscribe((resp: any) => {
            this.inprogress = false
            if (resp.success == false) {
                
                this.alert.error("Not Updated")
                return
            }
            this.api.user = resp.data;
            // this.api.user.mentor.contact = this.contact;
            // this.api.user.mentor.address = this.address;
            this.user = this.api.user
            console.log(this.user)
            localStorage.removeItem('this.api.user')
            localStorage.setItem('user', JSON.stringify(this.user))
            this.alert.success('Profile Update successfully')
            // this.updateProfileForm.reset()
            // this.updateProfileForm.controls.name.setErrors(null)
            // this.updateProfileForm.controls.contact.setErrors(null)
            // this.updateProfileForm.controls.address.setErrors(null)
        })
    }
}
